.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* TableStyles.css */
.table-container {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.bg-gradient-light-violet {
  background: linear-gradient(90deg, #5534B7 0%, #2F235D 100%);
}

.bg-blurred {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(17px);
}

.toggle:focus {
  outline: 0;
}

.toggle {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  height: 28px;
  width: 48px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 0;
  background: #caccd2;
  border: 2px solid #caccd2;
  transition: all 0.2s ease;
}

.toggle:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
  transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDRMNCA3TDEwIDEiIHN0cm9rZT0iIzBGQzNCRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="),
      #fff;
  background-position: 50% 50%;
  background-size: 0%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.toggle:checked {
  border: 2px solid #0fc3bd;
  background: #0fc3bd;
}

.toggle:checked:after {
  transform: translatex(20px);
  background-size: 50%;
}


/* Active item triangle in top bar */
.triangle-container {
  position: relative;
}

.triangle-container:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #412B88;
  border-width: 6px;
  margin-left: -6px;
  margin-top: -12px;
}
