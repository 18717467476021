.scrollbar::-webkit-scrollbar {
    width: 3px;
}


/* Define the track style */
.scrollbar::-webkit-scrollbar-track {
    background-color: white;
    /* border: 1px solid #ccc; */
}


/* Define the thumb style */
.scrollbar::-webkit-scrollbar-thumb {
    background: #6E43F8;
    /* border-radius: 5px; */
}

/* for firefox */
.scrollbar {
    scrollbar-color: #6E43F8 white;
    scrollbar-width: 3px;
}