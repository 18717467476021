@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary);
}

h2 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary);
}

*::-webkit-scrollbar {
    width: 10px;
  }
  
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  *::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 14px;
    border: 3px solid var(--primary);
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }